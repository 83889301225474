<template>
    <section class="mt-3">
        <section class="position-relative">
            <cargando v-if="cargandoCuarta" />

            <div class="bg-white br-12 py-3 px-3">
                <p class="text-general f-500 f-18 mb-3">
                    Categorías más vendidas
                </p>
                <div class="row mx-0">
                    <div class="col px-0">
                        <div class="row mx-0">
                            <div v-for="(categoria, index) in categorias" :key="index" class="mr-3" style="width:115px;">
                                <div class="card-cat text-center">
                                    {{ categoria.nombre }}
                                </div>
                                <p class="text-general text-center f-15 mt-2">
                                    {{ separadorNumero(categoria.total) }}
                                </p>
                                <p class="text-general text-center f-500">
                                    {{ categoria.porcentaje }}%
                                </p>
                            </div>
                        </div>
                    </div>
                    <div v-if="teams.length > 5" class="bg-general3 cr-pointer text-white text-center br-12 d-flex flex-column justify-center" style="width: 115px; height: 80px;" @click="modalGris(7)">
                        <p class="text-center">
                            Ver más <br />
                            <i class="icon-angle-right" />
                        </p>
                    </div>
                </div>
            </div>
            <div class="bg-white br-12 py-3 px-3 mt-3">
                <p class="text-general f-500 f-18 mb-3">
                    Productos más vendidos
                </p>
                <div class="row mx-0">
                    <div class="col px-0">
                        <div class="row mx-0">
                            <div v-for="producto in productosVenta" :key="producto.id_producto" class="mr-3" style="115px;">
                                <el-tooltip placement="bottom" :content="producto.nombre" effect="light">
                                    <img :src="producto.foto" class="br-12 obj-cover border" width="115" height="115" />
                                </el-tooltip>
                                <p class="text-general text-center f-15 mt-2 tres-puntos">
                                    {{ separadorNumero(producto.valor) }}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div v-if="teams.length > 5" class="bg-general3 text-white text-center br-12 d-flex flex-column justify-center cr-pointer" style="width: 115px; height: 115px;" @click="modalGris(8)">
                        <p class="text-center">
                            Ver más <br />
                            <i class="icon-angle-right" />
                        </p>
                    </div>
                </div>
            </div>
            <div class="bg-white br-12 py-3 px-3 mt-3">
                <p class="text-general f-500 f-18 mb-3">
                    Productos más vendidos con mayor presencia en pedidos
                </p>
                <div class="row mx-0">
                    <div class="col px-0">
                        <div class="row mx-0">
                            <div v-for="producto in productosCantidad" :key="producto.id_producto" class="mr-3" style="115px;">
                                <el-tooltip placement="bottom" :content="producto.nombre" effect="light">
                                    <img :src="producto.foto" class="br-12 obj-cover border" width="115" height="115" />
                                </el-tooltip>
                                <p class="text-general text-center f-15 mt-2 tres-puntos">
                                    {{ agregarSeparadoresNumero(producto.valor) }}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div v-if="teams.length > 5" class="bg-general3 cr-pointer text-white text-center br-12 d-flex flex-column justify-center" style="width: 115px; height: 115px;" @click="modalGris(9)">
                        <p class="text-center">
                            Ver más <br />
                            <i class="icon-angle-right" />
                        </p>
                    </div>
                </div>
            </div>
        </section>
        <section class="position-relative">
            <cargando v-if="cargandoQuinta" />

            <div class="row mx-0 mt-3">
                <div class="col bg-white py-3 br-8 mr-2">
                    <div class="row mx-0 mb-4">
                        <div class="col-auto px-0 f-18 f-500 text-general">
                            Compradores por genero
                        </div>
                        <div v-if="teams.length > 5" class="cr-pointer bg-general3 text-white br-20 f-14 px-3 d-middle-center ml-auto cr-pointer" @click="verMasVentas(4)">
                            Ver más
                        </div>
                    </div>
                    <div v-for="team in generoCantidad" :key="team.id" class="row mx-0 pl-4 align-items-end mb-3">
                        <div class="col-4 pl-0">
                            <p class="f-16 text-general">
                                {{ team.nombre }}
                            </p>
                            <p class="f-16 text-general2">
                                <i>
                                    {{ agregarSeparadoresNumero(team.total) }}
                                </i>
                            </p>
                        </div>
                        <div class="col pb-3">
                            <el-popover
                            placement="bottom"
                            width="300"
                            popper-class="p-0 br-12 px-3 py-3"
                            trigger="hover"
                            >
                                <p class="text-general f-12 f-600 mb-2 pl-4">
                                    {{ team.nombre }}
                                </p>
                                <div class="row mx-0">
                                    <div style="width:12px;height:12px;background-color:#01B8AA" />
                                    <div class="col px-0 text-center text-general2">
                                        Mujeres
                                    </div>
                                    <div class="col px-0 text-center text-general2">
                                        {{ agregarSeparadoresNumero(team.mujer) }}
                                    </div>
                                </div>
                                <div class="row mx-0">
                                    <div style="width:12px;height:12px;background-color:#374649" />
                                    <div class="col px-0 text-center text-general2">
                                        Hombres
                                    </div>
                                    <div class="col px-0 text-center text-general2">
                                        {{ agregarSeparadoresNumero(team.hombre) }}
                                    </div>
                                </div>
                                <div class="row mx-0">
                                    <div style="width:12px;height:12px;background-color:#FD625E" />
                                    <div class="col px-0 text-center text-general2">
                                        Otros
                                    </div>
                                    <div class="col px-0 text-center text-general2">
                                        {{ agregarSeparadoresNumero(team.feminista) }}
                                    </div>
                                </div>
                                <div slot="reference" class="row mx-0 bg-fondo" style="height:10px;">
                                    <div class="h-100" style="background-color:#01B8AA;" :style="{width:`${team.porcentajeMujer}%`}" />
                                    <div class="h-100" style="background-color:#374649;" :style="{width:`${team.porcentajeHombre}%`}" />
                                    <div class="h-100" style="background-color:#FD625E;" :style="{width:`${team.porcentajeOtro}%`}" />
                                </div>
                            </el-popover>
                        </div>
                    </div>
                </div>
                <div class="col bg-white py-3 br-8 ml-2">
                    <div class="row mx-0 mb-4">
                        <div class="col-auto px-0 f-18 f-500 text-general">
                            Compradores por dispositivo
                        </div>
                        <div v-if="teams.length > 5" class="cr-pointer bg-general3 text-white br-20 f-14 px-3 d-middle-center ml-auto" @click="verMasVentas(5)">
                            Ver más
                        </div>
                    </div>
                    <div v-for="dis in dispositivoCantidad" :key="dis.id" class="row mx-0 pl-4 align-items-end mb-3">
                        <div class="col-4 pl-0">
                            <p class="f-16 text-general">
                                {{ dis.nombre }}
                            </p>
                            <p class="f-16 text-general2">
                                <i>
                                    {{ agregarSeparadoresNumero(dis.total) }}
                                </i>
                            </p>
                        </div>
                        <div class="col pb-3">
                            <el-popover
                            placement="bottom"
                            width="300"
                            popper-class="p-0 br-12 px-3 py-3"
                            trigger="hover"
                            >
                                <p class="text-general f-12 f-600 mb-2 pl-4">
                                    {{ dis.nombre }}
                                </p>
                                <!-- Desconocido -->
                                <div class="row mx-0">
                                    <div :style="`width:12px;height:12px;background-color:#01B8AA`" />
                                    <div class="col px-0 text-center text-general2">
                                        Desconocido
                                    </div>
                                    <div class="col px-0 text-center text-general2">
                                        {{ agregarSeparadoresNumero(dis.desconocido) }}
                                    </div>
                                </div>
                                <!-- Android -->
                                <div class="row mx-0">
                                    <div :style="`width:12px;height:12px;background-color:#374649`" />
                                    <div class="col px-0 text-center text-general2">
                                        Android
                                    </div>
                                    <div class="col px-0 text-center text-general2">
                                        {{ agregarSeparadoresNumero(dis.android) }}
                                    </div>
                                </div>
                                <!-- IOS -->
                                <div class="row mx-0">
                                    <div :style="`width:12px;height:12px;background-color:#FD625E`" />
                                    <div class="col px-0 text-center text-general2">
                                        IOS
                                    </div>
                                    <div class="col px-0 text-center text-general2">
                                        {{ agregarSeparadoresNumero(dis.ios) }}
                                    </div>
                                </div>
                                <!-- Web -->
                                <div class="row mx-0">
                                    <div :style="`width:12px;height:12px;background-color:#F2C80F`" />
                                    <div class="col px-0 text-center text-general2">
                                        Web
                                    </div>
                                    <div class="col px-0 text-center text-general2">
                                        {{ agregarSeparadoresNumero(dis.web) }}
                                    </div>
                                </div>
                                <div slot="reference" class="row mx-0 bg-fondo" style="height:10px;">
                                    <div class="h-100" style="background-color:#01B8AA" :style="{ width:`${dis.porcentajeDesconocido}%` }" />
                                    <div class="h-100" style="background-color:#374649" :style="{ width:`${dis.porcentajeAndroid}%` }" />
                                    <div class="h-100" style="background-color:#FD625E" :style="{ width:`${dis.porcentajeIos}%` }" />
                                    <div class="h-100" style="background-color:#F2C80F" :style="{ width:`${dis.porcentajeWeb}%` }" />
                                </div>
                            </el-popover>
                        </div>
                    </div>
                </div>
            </div>

            <div class="bg-white br-12 py-3 px-3 mt-3">
                <p class="text-general f-500 f-18 mb-3">
                    Compradores por edades
                </p>
                <div class="d-flex mx-0" style="height:30px;">
                    <template v-for="(com, c) in compradores_X_edades">
                        <el-tooltip :key="c" placement="bottom" :content="`${com.edad}: ${com.porcentaje}%`" effect="light">
                            <div class="h-100" :class="c == 0 ? 'br-l-8' : c == 10 ? 'br-r-8' : ''" :style="`background-color:${com.color};width:${com.porcentaje}%`" />
                        </el-tooltip>
                    </template>
                </div>
                <div class="row mx-0 mt-4">
                    <div v-for="(com, x) in compradores_X_edades" :key="x" class="card-compradores p-2 mr-2 mb-2">
                        <div class="row mx-0">
                            <div class="rounded-circle mt-1" :style="`width:18px;height:18px;background-color:${com.color}`" />
                            <div class="col text-general f-500 f-17 pr-0 pl-2">
                                {{ com.edad }}
                            </div>
                        </div>
                        <div class="row mx-0 pl-4">
                            <div class="col text-general2 f-15 pl-0">
                                {{ separadorNumero(com.valor) }}
                            </div>
                        </div>
                        <div class="row mx-0 pl-4">
                            <div class="col text-general2 f-15 pl-0">
                                {{ agregarSeparadoresNumero(com.cantidad) }} Pedidos
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- Partials -->
        <modal-ventas-ultimos ref="ventasUltimos" :fecha="fecha" show-fecha :teams="teams" />
        <modal-progress-gris ref="progressGris" :img-producto="showProducto" :teams="teams" show-fecha :fecha="fecha" />
    </section>
</template>

<script>
import comparativa from "~/services/teams/comparativa"

const colors = ['#01B8AA','#374649', '#FD625E', '#F2C80F', '#5F6B6D', '#8AD4EB', '#FE9666', '#3599B8', '#72D4CC', '#A66999', '#E1B0B0']


export default {
    components: {
        modalProgressGris: () => import('../partials/modalProgressGris.vue'),
        modalVentasUltimos: () => import('../partials/modalVentasUltimos.vue'),
    },
    props: {
        teams: {
            type: Array,
            default: () => []
        },
        fecha: {
            type: Array,
            default: () => []
        },
        changed: {
            type: Boolean,
            default: true
        },
        changedFecha: {
            type: Boolean,
            default: true
        }
    },
    data(){
        return {
            titulo_modal: '',
            verRangoFecha: false,
            verImgProducto: false,
            compradores_X_edades: [],
            categorias: [],
            productosVenta: [],
            productosCantidad: [],
            generoCantidad: [],
            dispositivoCantidad: [],
            showProducto: false,
            cargandoCuarta: true,
            cargandoQuinta: true,

        }
    },
    watch: {
        changed(){
            this.cargandoCuarta = true
            this.cargandoQuinta = true


            this.init()
        },
        changedFecha(){
            this.cargandoCuarta = true
            this.cargandoQuinta = true

            this.init()
        }
    },
    async mounted(){
        await this.init()
    },
    methods: {
        modalGris(tipo){
            this.showProducto = [8].includes(tipo)
            this.$refs.progressGris.toggle(tipo);
        },
        verMasVentas(tipo){
            this.$refs.ventasUltimos.toggle(tipo);
        },
        async init(){
            await this.cuartasGraficas()
            await this.quintasGraficas()
        },
        verMasClientes(){
            this.titulo_modal = 'Categorías más vendidas'
            this.verRangoFecha = true;
            this.$refs.progressGris.toggle();
        },
        productosVendidos(){
            this.titulo_modal = 'Productos más vendidos'
            this.verImgProducto = true;
            this.$refs.progressGris.toggle();
        },
        async cuartasGraficas(){
            try {
                this.cargandoCuarta = true
                const teams = this.teams.map(el => el.id).toString()

                const params = {
                    teams,
                    fecha: this.fecha
                }
                const { data } = await comparativa.cuartasGraficas(params)

                const totalCategorias = _.sumBy(data.categorias, 'total')

                this.categorias = data.categorias.map(el => {
                    const porcentaje = totalCategorias > 0 ? (el.total * 100) / totalCategorias:  0
                    el.porcentaje = Math.round(porcentaje)
                    return el
                })

                this.productosVenta = data.productosVenta
                this.productosCantidad = data.productosCantidad
            } catch (error){
                this.error_catch(error)
            } finally{
                this.cargandoCuarta = false

            }
        },
        async quintasGraficas(){
            try {
                this.cargandoQuinta = true
                const teams = this.teams.map(el => el.id).toString()

                const params = {
                    teams,
                    fecha: this.fecha
                }
                const { data } = await comparativa.quintasGraficas(params)

                this.generoCantidad = data.generoCantidad.map(el => {
                    let total = el.mujer + el.hombre + el.feminista
                    const porcentajeMujer = total > 0 ? (el.mujer * 100) / total:  0
                    const porcentajeHombre = total > 0 ? (el.hombre * 100) / total:  0
                    const porcentajeOtro = total > 0 ? (el.feminista * 100) / total:  0

                    el.total = total
                    el.porcentajeMujer = porcentajeMujer
                    el.porcentajeHombre = porcentajeHombre
                    el.porcentajeOtro = porcentajeOtro
                    return el
                })
                
                this.dispositivoCantidad = data.dispositivoCantidad.map(el => {
                    let total = el.desconocido + el.web + el.android + el.ios
                    const porcentajeDesconocido = total > 0 ? (el.desconocido * 100) / total:  0
                    const porcentajeWeb = total > 0 ? (el.eb * 100) / total:  0
                    const porcentajeAndroid = total > 0 ? (el.android * 100) / total:  0
                    const porcentajeIos = total > 0 ? (el.ios * 100) / total:  0

                    el.total = total
                    el.porcentajeDesconocido = porcentajeDesconocido
                    el.porcentajeWeb = porcentajeWeb
                    el.porcentajeAndroid = porcentajeAndroid
                    el.porcentajeIos = porcentajeIos
                    return el
                })

                this.compradores_X_edades = data.edades.map((el, index) => ({...el, color: colors[index]}))

            } catch (error){
                this.error_catch(error)
            } finally{
                this.cargandoQuinta = false
            }
        },
    }
}
</script>
<style lang="scss" scoped>
.card-cat{
    width: 115px;
    height: 80px;
    border: 1px solid #DBDBDB;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #F6F9FB;
    color: #707070;
    font-size: 14px;

}

.card-compradores{
    min-width: 183px;
    height: 100px;
    border: 1px solid #EBEBEB;
    border-radius: 12px;
}
</style>